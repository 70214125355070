import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { markErrorAsHandled } from '@confluence/graphql';
import { getStatusCode } from '@confluence/error-boundary';

import type { OrgDefaultClassificationLevelIdQuery as OrgDefaultClassificationLevelIdQueryType } from '../queries/__types__/OrgDefaultClassificationLevelIdQuery';
import { OrgDefaultClassificationLevelIdQuery } from '../queries/OrgDefaultClassificationLevelIdQuery.graphql';

type UseOrgDefaultClassificationLevelIdProps = {
	skip?: boolean;
};

type UseOrgDefaultClassificationLevelIdType = {
	orgDefaultClassificationLevelId: string | null;
	orgDefaultClasificationLoading: boolean;
	orgDefaultClassificationError?: ApolloError;
};

const shouldMarkErrorAsHandled = (error?: ApolloError) => {
	if (!error) return true;
	const statusCode = getStatusCode(error?.graphQLErrors?.[0]);
	if (!statusCode) return true;
	return !!(parseInt(statusCode) >= 400 && parseInt(statusCode) < 500);
};

export const useOrgDefaultClassificationLevelId = ({
	skip,
}: UseOrgDefaultClassificationLevelIdProps): UseOrgDefaultClassificationLevelIdType => {
	const { data, loading, error } = useQuery<OrgDefaultClassificationLevelIdQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		OrgDefaultClassificationLevelIdQuery,
		{ skip },
	);

	if (shouldMarkErrorAsHandled(error)) {
		markErrorAsHandled(error);
	}

	const orgDefaultClassificationLevelId = data?.orgDefaultClassificationLevelId ?? null;

	return {
		orgDefaultClassificationLevelId,
		orgDefaultClasificationLoading: loading,
		orgDefaultClassificationError: !shouldMarkErrorAsHandled(error) ? error : undefined,
	};
};
